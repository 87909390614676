<template>
  <div>
    <el-form
      :rules="rules"
      style="width:80%"
      ref="questionForm"
      :model="question"
      label-width="100px"
    >
      <el-form-item label="题目名称">
        <span
          class="question-title"
          v-html="question.questionTitle"
        ></span>
        <el-button
          v-if="isView == true"
          style="float:right;margin-right:20px"
          type="primary"
          @click="onAnswerOpen"
        >答题</el-button>

      </el-form-item>
      <template v-if="isView == false">
        <el-form-item
          v-if="isReview == true"
          label="您的答案"
        >
          <el-input
            disabled
            v-for="(item,i) in answer.contentArray"
            :key="i"
            v-model="answer.contentArray[i]"
            style="margin-bottom:5px"
          ></el-input>
        </el-form-item>
        <template v-else>
          <el-form-item
            v-for="(item,i) in question.items"
            :key="i"
            :prop="`items[${i}].correct`"
            :label="`${item.prefix}`"
            :rules="{ validator: sensitiveValidator, trigger: 'change' }"
          >
            <el-input
              v-model="item.correct"
              :placeholder="`输入${item.prefix}的答案`"
              style="margin-bottom:5px"
            ></el-input>
          </el-form-item>
        </template>
        <template v-if="isReview">
          <el-form-item label="正确答案">
            <div
              v-for="(item,i) in question.correctArray"
              :key="i"
              class="question-title"
            >{{item}}</div>
          </el-form-item>
          <el-form-item label="解析">
            <span
              class="question-title"
              v-html="question.analyze"
            ></span>
          </el-form-item>
        </template>
      </template>

    </el-form>
    <div
      style="text-align:center"
      v-if="isView == false"
    >
      <el-button
        v-if="isReview == false"
        type="primary"
        @click="onAnswerSubmit"
      >提交</el-button>
      <template v-else>
        <el-button
          type="primary"
          @click="onAnswerCollect"
        >收藏</el-button>
        <el-button
          type="primary"
          @click="onClose"
        >关闭</el-button>
      </template>
    </div>
  </div>
</template>
<script>
import { sensitiveFilter } from '@/api/common'
const sensitiveValidator = (rule, value, callback) => {
  sensitiveFilter({ desc: value }).then(res => {
    if (!res.success) {
      callback(new Error(res.message));
    } else {
      callback();
    }
  })
}
export default {
  name: 'GapFilling',
  props: {
  },
  data() {
    return {
      sensitiveValidator,
      isReview: false,
      isView: true,
      hasFavorite: false,
      hasAnswer: false,
      question: {
        questionId: null,
        questionTitle: null,
        correctAnswer: null,
        analyze: null,
        items: null,
        correct: null,
        content: null,
        contentArray: null,
        workUrl: null,
        correctArray: null,
        id: null,
        questionTypeName: null,
        hasFavorite: null,
        hasAnswer: null,
        answerCorrect: null
      },
      answer: {
        content: null,
        contentArray: null,
        correct: null,
        questionId: null,
        workUrl: null
      },
      rules: {
        // correct: [
        // { validator: sensitiveValidator, trigger: 'change' }

        // { required: true, message: '请输入答案', trigger: 'change' }
        // ],
      }
    }
  },
  methods: {
    init(question, isView, hasFavorite, isReview,) {
      this.question = question;
      this.isView = isView;
      this.hasFavorite = hasFavorite;
      this.isReview = isReview;
      this.question.correct = [];
      for (let i in this.question.items) {
        this.$set(this.question.items[i], 'correct', null)
      }
      console.log(this.question.items)
    },
    initAnswer(answer) {
      this.answer = answer;
    },
    onAnswerSubmit() {
      this.$refs['questionForm'].validate((valid) => {
        if (valid) {
          for (let i in this.question.items) {
            this.question.correct.push(this.question.items[i].correct);
          }
          let answerData = {
            questionId: this.question.questionId,
            contentArray: this.question.correct
          };
          // console.log(answerData)
          // return;
          this.$emit('submit', answerData);
        } else {
          return false;
        }
      })
    },
    onAnswerCollect() {
      let data = {
        id: this.question.questionId,
        hasFavorite: this.hasFavorite
      };
      this.$emit('collect', data);
    },
    onClose() {
      this.$emit('close');
    },
    onAnswerOpen() {
      this.$emit('answer', this.question)
    },
  }
}
</script>
<style lang="scss" scoped>
.question-title {
  color: black;
}
.question-desc {
  color: #606266;
  margin-left: 20px;
}
</style>