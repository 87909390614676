<template>
    <div>
        <el-form ref="questionForm" :model="question" :rules="rules" label-width="100px">
            <el-form-item label="题目名称">
                <span class="question-title" v-html="question.questionTitle"></span>
                   <el-button v-if="isView == true" style="float:right;margin-right:20px" type="primary" @click="onAnswerOpen">答题</el-button>
            
            </el-form-item>
             <el-form-item label="选项">
                 <el-row :gutter="2">
                   <el-col :span="10" v-for="(item,i) in question.items" :key="i">
                        <span class="question-title">{{item.prefix}}</span>
                        <span class="question-desc" v-html="item.content"></span>
                    </el-col>
                </el-row>
            </el-form-item>
            <template v-if="isView == false">
            <el-form-item :label="isReview == true?'您的答案':'请选择答案'" :prop="isReview == false?'correct':''">
                <el-checkbox-group v-model="isReview == true ? answer.contentArray:question.correct" :disabled="isReview">
                <el-checkbox :label="item.prefix" :key="i" v-for="(item,i) in question.items" name="correct"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <template v-if="isReview">
                <el-form-item label="正确答案">
                    <span class="question-title">{{question.correctAnswer}}</span>
                </el-form-item>
                <el-form-item label="解析">
                    <span class="question-title" v-html="question.analyze"></span>
                </el-form-item>
                </template>
            </template>
            <div style="text-align:center" v-if="isView == false">
                 <el-button v-if="isReview == false" type="primary" @click="onAnswerSubmit">提交</el-button>
                 <template v-else>
                    <el-button type="primary" @click="onAnswerCollect">收藏</el-button>
                    <el-button type="primary" @click="onClose">关闭</el-button>
                 </template>
            </div>
        </el-form>
    </div>
</template>
<script>
export default {
    name:'multipleChoice',
    props:{
    },
    data(){
        return{
            isReview:false,
            isView:true,
            hasFavorite:false,
            hasAnswer:false,
            question:{
                questionId:null,
                questionTitle:null,
                correctAnswer:null,
                analyze:null,
                items:null,
                correct:null,
                content:null,
                contentArray:null,
                workUrl:null,
                 id:null,
                questionTypeName:null,
                hasFavorite:null,
                hasAnswer:null,
                answerCorrect:null
            },
            answer:{
                content:null,
                contentArray:null,
                correct:null,
                questionId:null,
                workUrl:null
            },
            rules: {
                correct: [
                    { type: 'array', required: true, message: '请至少选择一个答案', trigger: 'change' }
                ],
            }
        }
    },
    methods:{
        init(question,isView,hasFavorite,isReview,){
            this.question = question;
            this.isView = isView;
            this.hasFavorite = hasFavorite;
            this.isReview = isReview;
            this.question.correct = []
        },
        initAnswer(answer){
            this.answer = answer;
        },
        onAnswerSubmit(){
            this.$refs['questionForm'].validate((valid) => {
                if(valid){
                    let answerData = {
                        questionId:this.question.questionId,
                        contentArray:this.question.correct
                    };
                    // console.log(answerData)
                    // return;
                    this.$emit('submit',answerData);
                }else{
                    return false;
                }
            })
        },
         onAnswerCollect(){
             let data = {
                 id:this.question.questionId,
                 hasFavorite:this.hasFavorite
             };
            this.$emit('collect',data);
        },
        onClose(){
            this.$emit('close');
        },
         onAnswerOpen(){
            this.$emit('answer',this.question)
        },
    }
}
</script>
<style lang="scss" scoped>
.question-title{
    color: black;
}
.question-desc{
    color: #606266;
    margin-left: 20px;
}
</style>