<template>
    <div>
        <el-form style="width:80%" ref="questionForm" :model="question" :rules="rules" label-width="100px">
            <el-form-item label="题目名称">
                <span class="question-title" v-html="question.questionTitle"></span>
                   <el-button v-if="isView == true" style="float:right;margin-right:20px" type="primary" @click="onAnswerOpen">答题</el-button>
            
            </el-form-item>
            <template v-if="isView == false">
                 <el-form-item label="输入答案">
                <el-button type="primary" size="mini" @click="toCodingLab">进入实验室练习</el-button>
            </el-form-item>
             <el-form-item label="查看答案">
                 <el-button type="text" @click="toCodingLabAnswer">查看答案</el-button>
            </el-form-item>
            </template>
        </el-form>
            <div v-if="isView == false" style="text-align:center">
                <el-button type="primary" @click="onAnswerCollect">收藏</el-button>
                <el-button :disabled="btnDisable" type="primary" @click="onAnswerSubmit">已完成</el-button>
                <el-button type="primary" @click="onClose">关闭</el-button>
            </div>
    </div>
</template>
<script>
export default {
    name:'CodingAnswer',
    props:{
    },
    data(){
        return{
            btnDisable:true,
            isReview:false,
            isView:true,
            hasFavorite:false,
            hasAnswer:false,
            question:{
                questionId:null,
                questionTitle:null,
                correctAnswer:null,
                analyze:null,
                items:null,
                correct:null,
                content:null,
                contentArray:null,
                workUrl:null,
                answerUrl:null,
                labUrl:null,
                 id:null,
                questionTypeName:null,
                hasFavorite:null,
                hasAnswer:null,
                answerCorrect:null
            },
            rules: {
                type: [
                    { required: true, message: '请选择答案', trigger: 'change' }
                ],
            }
        }
    },
    methods:{
         init(question,isView,hasFavorite,isReview,){
            this.btnDisable = true;
            this.question = question;
            this.isView = isView;
            this.hasFavorite = hasFavorite;
            this.isReview = isReview;
        },
        onAnswerSubmit(){
            this.$emit('submit');
        },
        onAnswerCollect(){
            let data = {
                id:this.question.questionId,
                hasFavorite:this.hasFavorite
            };
            this.$emit('collect',data);
        },
        onClose(){
            this.$emit('close');
        },
        toCodingLab(){
            this.btnDisable = false;
            window.open(this.question.labUrl);
        },
        toCodingLabAnswer(){
            window.open(this.question.answerUrl);
        },
         onAnswerOpen(){
            this.$emit('answer',this.question)
        },
    }
}
</script>
<style lang="scss" scoped>
.question-title{
    color: black;
}
.question-desc{
    color: #606266;
    margin-left: 20px;
}

</style>