<template>
  <div>
    <!-- <div v-if="isUserInfo" class="btn-title">
      <button @click="changeWorkType('all')" :class="workType == 'all'?'actived':''">练习过的题目</button>
          <div style="float:right;height:42px;margin-top:-16px">
             <div class="curriculum-block">
             <el-switch
          @change="changeWorkType('error')"
            v-model="errorWork"
            active-color="#f59a23">
          </el-switch>
          <span :class="errorWork == true?'curriculum-span-light':'curriculum-span'">回答错误的题目</span>
            </div>   
            <div class="curriculum-block">
             <el-switch
          @change="changeWorkType('collected')"
            v-model="collectWork"
            active-color="#f59a23">
          </el-switch>
          <span :class="collectWork == true?'curriculum-span-light':'curriculum-span'">收藏的题目</span>
            </div>  
          </div>
      </div> -->
    <template v-if="isUserInfo == false">
      <ul class="tags">
        <li
          style="min-width: 160px;"
          v-for="(items, index) in subjectList"
          :key="items.id"
          v-if="index == 0"
          :class="['tag-item', {active: queryParam.subjectId == items.id}]"
          @click="handleChangeTag('subject',items.id)"
        >{{items.name}}
        </li>
        <li>
          <ul>
            <li
              v-if="index != 0"
              v-for="(items, index) in subjectList"
              :key="index"
              :class="['tag-item', {active: queryParam.subjectId == items.id}]"
              @click="handleChangeTag('subject',items.id)"
            >{{items.name}}</li>
          </ul>
        </li>
      </ul>
      <ul class="tags">
        <li
          style="min-width: 160px;"
          v-for="(items, index) in questionBankList"
          :key="items.id"
          v-if="index == 0"
          :class="['tag-item', {active: queryParam.blankId == items.id}]"
          @click="handleChangeTag('bank',items.id)"
        >{{items.name}}
        </li>
        <li>
          <ul>
            <li
              v-if="index != 0"
              v-for="(items, index) in questionBankList"
              :key="index"
              :class="['tag-item', {active: queryParam.blankId == items.id}]"
              @click="handleChangeTag('bank',items.id)"
            >{{items.name}}</li>
          </ul>
        </li>
      </ul>
      <ul class="tags">
        <li
          style="min-width: 150px;"
          v-for="(items, index) in questionTypeList"
          :key="items.code"
          v-if="index == 0"
          :class="['tag-item', {active: queryParam.questionType == items.code}]"
          @click="handleChangeTag('questionType',items.code)"
        >{{items.name}}
        </li>
        <li>
          <ul>
            <li
              v-if="index != 0"
              v-for="(items, index) in questionTypeList"
              :key="index"
              :class="['tag-item', {active: queryParam.questionType == items.code}]"
              @click="handleChangeTag('questionType',items.code)"
            >{{items.name}}</li>
          </ul>
        </li>
      </ul>
    </template>
    <div style="margin-top:20px">
      <div class="search">
        <template v-if="isUserInfo">
          <div class="el-select">
            <el-select
              size="small"
              clearable
              v-model="queryParam.subjectId"
              placeholder="题目所属学科筛选"
              style="margin-right: 10px;width:200px"
              @change="getList"
            >
              <el-option
                v-for="item2 in subjectList"
                :key="item2.id"
                :label="item2.name"
                :value="item2.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="el-select">
            <el-select
              size="small"
              clearable
              v-model="queryParam.blankId"
              placeholder="题目所属题库筛选"
              style="margin-right: 10px;width:200px"
              @change="getList"
            >
              <el-option
                v-for="item2 in questionBankList"
                :key="item2.id"
                :label="item2.name"
                :value="item2.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="el-select">
            <el-select
              size="small"
              clearable
              v-model="queryParam.questionType"
              placeholder="题型筛选"
              style="margin-right: 10px;width:200px"
              @change="getList"
            >
              <el-option
                v-for="(item2,i) in questionTypeList"
                :key="item2.code"
                :label="item2.name"
                :value="item2.code"
              >
              </el-option>
            </el-select>
          </div>
          <div class="el-select">
            <el-select
              size="small"
              clearable
              v-model="queryParam.difficult"
              placeholder="题目难度筛选"
              style="margin-right: 10px;width:200px"
              @change="getList"
            >
              <el-option
                v-for="(item2,i) in difficultyList"
                :key="item2.id"
                :label="item2.name"
                :value="item2.id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="el-select"
            style="width:200px"
          >
            <el-select
              size="small"
              clearable
              v-model="queryParam.answerCorrect"
              placeholder="已回答题目筛选"
              style="margin-right: 10px;width:200px"
              @change="getList"
            >
              <el-option
                label="回答正确题目"
                value="1"
              >
              </el-option>
              <el-option
                label="回答错误题目"
                value="2"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="el-select"
            style="width:200px;margin-top:10px"
          >
            <el-select
              size="small"
              clearable
              v-model="queryParam.hasFavorite"
              placeholder="收藏的题目筛选"
              style="margin-right: 10px;width:200px"
              @change="getList"
            >
              <el-option
                label="未收藏"
                value="0"
              >
              </el-option>
              <el-option
                label="已收藏"
                value="1"
              >
              </el-option>
            </el-select>
          </div>

          <el-button
                @click="resetSearchValue"
                type="primary"
                size="small"
                style="margin-left: 15px; width: 90px;"
                >重 置</el-button>
        </template>
        <template v-else>
          <div class="el-select">
            <el-input
              style="width:200px;"
              size="small"
              clearable
              v-model="queryParam.keyword"
              @change="getList"
              placeholder="题目名称搜索"
            ><i slot="prefix" style="cursor: pointer;" class="el-input__icon el-icon-search"></i></el-input>
          </div>
          <div class="el-select">
            <span class="rate-item">题目难度筛选</span>
            <el-rate
              style="line-height:16x;display: inline-block"
              v-model="queryParam.difficult"
              @change="getList"
            ></el-rate>
          </div>
        </template>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
        >
        </el-table-column>
        <el-table-column
          prop="subjectName"
          width="100"
          label="考试学科"
        >
        </el-table-column>
        <el-table-column
          prop="questionBankName"
          width="150"
          label="题库"
        >
        </el-table-column>
        <el-table-column label="题目名称">
          <template slot-scope="scope">
            <el-tooltip
              popper-class="question-tooltip"
              class="item"
              effect="dark"
              :content="htmlFormatter(scope.row.shortTitle)"
              placement="top-start"
            >
              <div
                class="short-title"
                v-html="htmlFormatter(scope.row.shortTitle)"
              ></div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="难度"
        >
          <template slot-scope="scope">
            {{scope.row.difficult | difficultFilter}}
          </template>
        </el-table-column>
        <el-table-column
          prop="questionTypeName"
          width="100"
          label="题型"
        >
        </el-table-column>
        <el-table-column
          v-if="isUserInfo"
          label="答题情况"
        >
          <template slot-scope="scope">
            {{scope.row.answerCorrect | answerCorrectFilter}}
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          align="center"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleReview(scope.row)"
            >查看</el-button>
            <el-button
              type="text"
              @click="handleAnswer(scope.row)"
            >{{scope.row.hasAnswer | answerFilter}}</el-button>
            <div
              class="collect-btn"
              @click="handleCollect(scope.row)"
            >
              <i :class="['iconfont', scope.row.hasFavorite ? 
                'iconyishoucang0081ef active': 'iconshoucang979EB1', 'can-active']"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="content-pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="queryParam.pageNo"
          :page-size="queryParam.pageSize"
          layout="total, prev, pager, next,jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="answerDialog.title"
      :visible.sync="answerDialog.visible"
      width="50%"
      :before-close="handleAnswerClose"
    >
      <div
        style="text-align:center;"
        slot="title"
      >
        <div class="question-type">
          <div>{{answerDialog.title}}</div>
          <div
            v-if="answerDialog.isReview == true && answerDialog.questionType == 'short'"
            class="question-type-desc"
          >简答题为主观题，请结合参考答案评价自己的回答</div>
        </div>
        <template v-if="answerDialog.isReview">
          <span
            v-if="answerDialog.isRight == 1"
            style="font-size:15px"
          >恭喜您答对了</span>
          <span
            v-else-if="answerDialog.isRight == 2"
            style="font-size:15px;color:#F40000"
          >很遗憾您回答错误</span>
        </template>
      </div>
      <div class="clear"></div>
      <component
        :is="currentQuestion"
        :ref="answerDialog.questionType"
        @answer="handleAnswer"
        @collect="handleCollect"
        @submit="handleAnswerSubmit"
        @close="handleAnswerClose"
      ></component>
      <template v-if="answerDialog.isReview == true && answerDialog.hasAnswer == 1">
        <img
          v-if="answerDialog.isRight == 1"
          class="answer-gif"
          :src="rightAnswer"
        />
        <img
          v-else-if="answerDialog.isRight == 2"
          class="answer-gif"
          :src="wrongAnswer"
        />
      </template>

    </el-dialog>
  </div>

</template>
<script>

import rightAnswer from '@/assets/image/rightAnswer.png';
import wrongAnswer from '@/assets/image/wrongAnswer.png';
import SingleChoice from '@/components/question/SingleChoice'
import MultipleChoice from '@/components/question/MultipleChoice.vue';
import TrueOrFalse from '@/components/question/TrueOrFalse.vue';
import GapFilling from '@/components/question/GapFilling.vue';
import ShortAnswer from '@/components/question/ShortAnswer.vue';
import CodingAnswer from '@/components/question/CodingAnswer.vue';
import { sensitiveFilter } from '@/api/common'
import { htmlFormatter } from '@/utils/index'

import {
  getQuestionOptions, getQuestionList,
  collectQuestion, getQuestionDetail, submitQuestion, getUserAnswerDetail
} from '@/api/user'

export default {
  name: 'questionList',
  components: {
    SingleChoice,
    MultipleChoice,
    TrueOrFalse,
    GapFilling,
    ShortAnswer,
    CodingAnswer,
  },
  props: {
    isUserInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentQuestion: null,
      questionTypeMap: {},
      rightAnswer,
      wrongAnswer,
      answerDialog: {
        hasFavorite: false,
        isRight: false,
        isReview: false,
        isView: true,
        title: null,
        visible: false,
        question: null,
        questionType: null
      },
      tableData: [],
      difficultyList: [
        { id: 1, name: '一星' },
        { id: 2, name: '二星' },
        { id: 3, name: '三星' },
        { id: 4, name: '四星' },
        { id: 5, name: '五星' }],
      subjectList: [],
      questionBankList: [],
      questionTypeList: [],
      queryParam: {
        pageSize: 10,
        pageNo: 1,
        selfBuild: 0,
        difficult: null,
        keyword: null,
        hasFavorite: null,
        hasAnswer: 0,
        answerCorrect: null,
        subjectId: null,
        blankId: null,
        questionType: null,
      },
      total: 0,
      workType: 'all',
      errorWork: false,
      collectWork: false,
      currentQuestionRecord: {}
    }
  },
  filters: {
    answerCorrectFilter(val) {
      if (val == 1) {
        return '正确';
      } else if (val == 2) {
        return '不正确';
      } else {
        return '——'
      }
    },
    difficultFilter(val) {
      if (val == 1) {
        return '一星'
      } else if (val == 2) {
        return '二星'
      } else if (val == 3) {
        return '三星'
      } else if (val == 4) {
        return '四星'
      } else if (val == 5) {
        return '五星'
      }
    },
    answerFilter(val) {
      if (val == 1) {
        return '已答过'
      } else {
        return '答题'
      }
    }
  },
  created() {
    this.workType = 'all';
    this.getQuestionOptions();
    this.resetSearchValue()
  },
  methods: {
    htmlFormatter,
    resetSearchValue(){
      this.queryParam = {
        pageSize: 10,
        pageNo: 1,
        selfBuild: 0,
        difficult: null,
        keyword: null,
        hasFavorite: null,
        hasAnswer: 0,
        answerCorrect: null,
        subjectId: null,
        blankId: null,
        questionType: null,
      }
      if (this.isUserInfo == true) {
        this.queryParam.hasAnswer = 1;
        this.queryParam.selfBuild = 2;
      } else {
        this.queryParam.hasAnswer = 0;
        this.queryParam.selfBuild = 2;
      }
      this.getList();
    },
    getList() {
      getQuestionList(this.queryParam).then(res => {
        if (res.code == 1) {
          let data = res.response;
          this.total = data.total;
          this.tableData = data.list;
        }
      })
    },
    handleChangeTag(type, id) {
      if (type == 'subject') {
        this.queryParam.subjectId = id;
      } else if (type == 'bank') {
        this.queryParam.blankId = id;
      } else {
        this.queryParam.questionType = id;
      }
      this.getList();
    },
    handleChangeBank(platform) {
      this.queryParam.selfBuild = platform == true ? 2 : 1;
      this.getQuestionOptions();
      this.getList();
    },
    handleCurrentChange(val) {
      this.queryParam.pageNo = val;
      this.getList();
    },
    changeWorkType(type) {
      if (type == 'error') {
        this.queryParam.hasFavorite = 0;
        this.queryParam.hasAnswer = 0;
        this.queryParam.answerCorrect = 2;
        this.errorWork = true;
        this.collectWork = false;
      } else if (type == 'collected') {
        this.queryParam.answerCorrect = 0;
        this.queryParam.hasAnswer = 0;
        this.queryParam.hasFavorite = 1;
        this.errorWork = false;
        this.collectWork = true;
      } else {
        this.queryParam.hasFavorite = 0;
        this.queryParam.answerCorrect = 0;
        this.queryParam.hasAnswer = 1;
        this.errorWork = false;
        this.collectWork = false;
      }
      this.workType = type;
      this.getList();
    },
    handleAnswer(record) {
      this.currentQuestionRecord = record;
      this.handleGetQuestionDetail(record.id, record);
      this.handleGetQuestionType(record.questionTypeName);
      this.answerDialog.hasFavorite = record.hasFavorite;
      this.answerDialog.hasAnswer = record.hasAnswer;
      this.answerDialog.isReview = record.hasAnswer == 1 ? true : false;
      this.answerDialog.isRight = record.answerCorrect;
      if (this.answerDialog.hasAnswer == 1) {
        this.handleGetAnswerDetail(record.id);
      }

      this.answerDialog.visible = true;
      this.answerDialog.isView = false;
    },
    handleReview(record) {
      this.handleGetQuestionDetail(record.id, record);
      this.handleGetQuestionType(record.questionTypeName);
      this.answerDialog.hasFavorite = record.hasFavorite;
      this.answerDialog.hasAnswer = record.hasAnswer;
      this.answerDialog.isReview = false;
      this.answerDialog.isView = true;
      this.answerDialog.visible = true;
    },
    handleGetQuestionDetail(questionId, record) {
      getQuestionDetail({ questionId: questionId }).then(res => {
        if (res.code == 1) {
          let data = res.response;
          let question = {
            questionId: data.id,
            analyze: data.analyze,
            items: data.items,
            questionTitle: data.title,
            correctAnswer: data.correct,
            correct: null,
            correctArray: data.correctArray,
            answerUrl: data.answerUrl,
            labUrl: data.labUrl,
            id: record.id,
            questionTypeName: record.questionTypeName,
            hasFavorite: record.hasFavorite,
            hasAnswer: record.hasAnswer,
            answerCorrect: record.answerCorrect
          }
          if (this.answerDialog.questionType == 'single') {
            this.$refs.single.init(question, this.answerDialog.isView, this.answerDialog.hasFavorite, this.answerDialog.isReview);
          } else if (this.answerDialog.questionType == 'multi') {
            this.$refs.multi.init(question, this.answerDialog.isView, this.answerDialog.hasFavorite, this.answerDialog.isReview);
          } else if (this.answerDialog.questionType == 'trueOrFalse') {
            this.$refs.trueOrFalse.init(question, this.answerDialog.isView, this.answerDialog.hasFavorite, this.answerDialog.isReview);
          } else if (this.answerDialog.questionType == 'filling') {
            this.$refs.filling.init(question, this.answerDialog.isView, this.answerDialog.hasFavorite, this.answerDialog.isReview);
          } else if (this.answerDialog.questionType == 'short') {
            this.$refs.short.init(question, this.answerDialog.isView, this.answerDialog.hasFavorite, this.answerDialog.isReview);
          } else if (this.answerDialog.questionType == 'coding') {
            this.$refs.coding.init(question, this.answerDialog.isView, this.answerDialog.hasFavorite, this.answerDialog.isReview);
          }
        }
      })
    },
    handleAnswerSubmit(answerData) {
      if (this.currentQuestion == 'CodingAnswer') {
        this.answerDialog.visible = false;
        this.getList();
      } else {
        submitQuestion(answerData).then(res => {
          if (res.code == 1) {
            this.$message.success('答题成功');
            this.currentQuestionRecord.hasAnswer = 1;
            // this.currentQuestionRecord.answerCorrect = 1;
            this.answerDialog.visible = false;
            this.handleAnswerClose();
            this.getList();
            // 答完题自动显示答题结果
            // if (this.currentQuestion != 'ShortAnswer') {
            //   this.handleAnswer(this.currentQuestionRecord);
            // }
          } else {
            this.$message.error(res.message);
          }
        })
      }
    },
    handleGetAnswerDetail(questionId) {
      getUserAnswerDetail({ questionId: questionId }).then(res => {
        if (res.code == 1) {
          let data = res.response.questionVm;
          this.answerDialog.answerDetail = {
            content: data.content,
            contentArray: data.contentArray,
            correct: data.correct,
            workUrl: data.workUrl
          };
          if (this.answerDialog.questionType == 'single') {
            this.$refs.single.initAnswer(this.answerDialog.answerDetail);
          } else if (this.answerDialog.questionType == 'multi') {
            this.$refs.multi.initAnswer(this.answerDialog.answerDetail);
          } else if (this.answerDialog.questionType == 'trueOrFalse') {
            this.$refs.trueOrFalse.initAnswer(this.answerDialog.answerDetail);
          } else if (this.answerDialog.questionType == 'filling') {
            this.$refs.filling.initAnswer(this.answerDialog.answerDetail);
          } else if (this.answerDialog.questionType == 'short') {
            this.$refs.short.initAnswer(this.answerDialog.answerDetail);
          } else if (this.answerDialog.questionType == 'coding') {
            this.$refs.coding.initAnswer(this.answerDialog.answerDetail);
          }
        } else {
          this.$message.error(res.message);
        }
      })
    },
    handleAnswerClose() {
      this.answerDialog.visible = false;
    },

    handleGetQuestionType(questionTypeName) {
      this.answerDialog.title = questionTypeName;
      if (questionTypeName == '单选题') {
        this.answerDialog.questionType = 'single'
        this.currentQuestion = 'SingleChoice';
        return;
      } else if (questionTypeName == '多选题') {
        this.answerDialog.questionType = 'multi'
        this.currentQuestion = 'MultipleChoice';
        return;
      } else if (questionTypeName == '判断题') {
        this.answerDialog.questionType = 'trueOrFalse'
        this.currentQuestion = 'TrueOrFalse';
        return;
      } else if (questionTypeName == '填空题') {
        this.answerDialog.questionType = 'filling'
        this.currentQuestion = 'GapFilling';
        return;
      } else if (questionTypeName == '简答题') {
        this.answerDialog.questionType = 'short'
        this.currentQuestion = 'ShortAnswer';
        return;
      } else if (questionTypeName == '实操题') {
        this.answerDialog.questionType = 'coding'
        this.currentQuestion = 'CodingAnswer';
        return;
      }
    },
    handleCollect(row) {
      collectQuestion({ questionId: row.id, favorite: row.hasFavorite ? 0 : 1 }).then(res => {
        if (res.code != 1) {
          this.$message.error(res.message)
        } else {
          if (!row.hasFavorite) {
            this.$message.success('收藏成功')
          }
          this.$emit('init');
          this.getList();
          this.handleAnswerClose();
        }
      })
    },
    getQuestionOptions() {
      let param = {
        selfBuild: this.queryParam.selfBuild
      };
      getQuestionOptions(param).then(res => {
        if (res.code == 1) {
          let result = res.response;
          this.subjectList = result.subjects;
          this.questionBankList = result.blanks;
          this.questionTypeList = result.questionTypes;
          if(this.isUserInfo && this.questionTypeList && this.questionTypeList[0].name && this.questionTypeList[0].name.indexOf('全部') > -1) this.questionTypeList.shift()
          if(!this.isUserInfo){
            this.subjectList.unshift({
              id: null,
              name: '考试学科选择 - 全部'
            });
            this.questionBankList.unshift({
              id: null,
              name: '考试题库选择 - 全部'
            });
          } 
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/views/question-list.scss';
.question-tooltip{
  max-width: 200px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>